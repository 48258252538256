import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';

import { cookieConsent } from './scripts/cookie/cookieConsent';
import { deleteCookie } from './scripts/cookie/deleteCookie';
import { fullWidthVideoPlayerFile } from './scripts/video/fullWidthVideoPlayerFile';
import { videoPopupPlayerFile } from './scripts/video/videoPopupPlayerFile';
import { delayHomeBannerVideoFile } from './scripts/video/delayHomeBannerVideoFile';
import { headerScripts } from './scripts/header/headerScripts';
import { stickyMenu } from './scripts/header/stickyMenu';
import { loadMore } from './scripts/load-more/loadMore';
import { formScripts } from './scripts/form/formScripts';
import { cardSectionGridSlider } from './scripts/card/cardSectionGridSlider';
import { cardSectionGridSliderNew } from './scripts/card/cardSectionGridSliderNew';
import { cardSectionSliderNew } from './scripts/card/cardSectionSliderNew';
import { cardSectionSlider } from './scripts/card/cardSectionSlider';
import { logoSlider } from './scripts/logo/logoSlider';
import { logoSectionGridSlider } from './scripts/logo/logoSectionGridSlider';
import { gallerySectionSlider } from './scripts/gallery/gallerySectionSlider';
import { jqueryViewer } from './scripts/gallery/jqueryViewer';
import { editorContentAdditions } from './scripts/misc/editorContentAdditions';
import { testimonialSlider } from './scripts/testimonial/testimonialSlider';
import { testimonialGridSlider } from './scripts/testimonial/testimonialGridSlider';

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on all pages
    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    logoSlider();
    cookieConsent();
    deleteCookie('PREF');
    headerScripts();
    stickyMenu();
    logoSectionGridSlider();
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    cardSectionGridSliderNew();
    cardSectionSliderNew();
    gallerySectionSlider();
    jqueryViewer('.gallery-images-viewer-v2');
    jqueryViewer('.gallery-images-viewer-v1');
    fullWidthVideoPlayerFile();
    videoPopupPlayerFile();
    delayHomeBannerVideoFile();
    editorContentAdditions();
    loadMore();
    testimonialGridSlider();
    testimonialSlider();
  },
};

$(window).on('resize', function () {
  $('.cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .gallery-section.v1 .gallery-wrapper, .cards-section-new.slider .cards-wrp, .cards-section-new.grid .cards-wrp, .testimonials-section .testimonials-row'
  ).flickity('resize');

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
  $('.page-banner-section').css('margin-top', $('.header').outerHeight());
});

$(window).on('load', function () {
  $('.cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .gallery-section.v1 .gallery-wrapper, .cards-section-new.slider .cards-wrp, .cards-section-new.grid .cards-wrp, .testimonials-section .testimonials-row'
  ).flickity('resize');
});
