/*
  Set the functionality of header navigation
*/

export function headerScripts() {
  // toggle class on hamburger icon click
  $('.mobile-burger-icon').click(function () {
    $('body, .mobile-burger-icon').toggleClass('show-menu');
  });

  // mobile sub menu navigation
  // level 1
  $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
    $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
    $(this).find('> a').click(function (e) {
      e.preventDefault();
      $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
    });
  });

  // level 2
  $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
    $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
    $(this).find('> a').click(function (e) {
      e.preventDefault();
      $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
    });
  });

  // return link click
  $('.return-link').each(function () {
    $(this).click(function () {
      $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
    });
  });

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
  $('.page-banner-section').css('margin-top', $('.header').outerHeight());
}