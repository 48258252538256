/*
  Testimonial slider
*/

export function testimonialSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.testimonials-section.slider .testimonials-row').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });

  $('.testimonials-section.slider').each(function () {
    $(this).find('.flickity-button').appendTo($(this).find('.flickity-button-wrp'));
  });
}