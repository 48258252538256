/*
  Makes the header nagivation sticky
*/

import { getCurrentScroll } from './getCurrentScroll';

export function stickyMenu() {
  var shrinkHeader = 5;

  $(window).scroll(function () {
    var scroll = getCurrentScroll();
    if (scroll >= shrinkHeader) {
      $('body').addClass('sticky-header');
    } else {
      $('body').removeClass('sticky-header');
    }
  });
}